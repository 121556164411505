<template>
    <div>
        <div v-if="!isHospital">

            <span class="font-16 d-block mb-1">{{ $t("forms.passportNum") }}</span>
            <base-input
                    v-model="passport"
                    class="mt-1"
                    :placeholder="$t('forms.number')"
                    :rules="[]"
            ></base-input>
        </div>
        <div v-if="isHospital" class="row">

            <div class="col-6">
                <span class="font-16 d-block mb-1">{{ $t("forms.signerName") }}</span>
                <base-input
                        v-model="signerName"
                        class="mt-1"
                        :rules="[]"
                ></base-input>
            </div>

            <div class="col-6">
                <span class="font-16 d-block mb-1">{{ $t("forms.signerPosition") }}</span>
                <base-input
                        v-model="signerPosition"
                        class="mt-1"
                        :rules="[]"
                ></base-input>
            </div>

        </div>
        <div v-if="isHospital" class="mt-4">
            <span class="font-16 d-block mb-1">{{ $t("forms.companyRegistrationNumber") }}</span>
            <base-input
                    v-model="companyRegistrationNumber"
                    class="mt-1"
                    :rules="[]"
            ></base-input>
        </div>

        <span class="font-16 d-block mt-5">{{ $t("forms.drawToSign") }}</span>
        <div class="text-right">
            <v-icon class="mt-2" color="register-primary" @click="undo"
            >mdi-backup-restore
            </v-icon
            >
        </div>
        <div v-if="!open" style="height: 300px"></div>
        <VueSignaturePad
                class="mt-5"
                v-if="open"
                style="box-shadow: 1px 1px 1px 1px lightgray;"
                id="signature"
                width="100%"
                height="300px"
                ref="signaturePad"
                :options="options"
        />
        <v-btn
                color="register-primary"
                elevation="0"
                height="50px"
                block
                class="radius-10 mt-3 px-10"
                @click="submitHandler"
        >
      <span class="white--text font-700 font-18">
        {{ $t("global.upload") }}</span
      >
        </v-btn>
    </div>
</template>

<script>
export default {
    props: {
        isHospital: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        signInput: "",
        passport: "",
        signerName: "",
        signerPosition: "",
        companyRegistrationNumber: "",
        options: {
            penColor: "#00f",
        },
        open: false,
    }),
    methods: {
        undo() {
            this.$refs.signaturePad.undoSignature();
        },
        submitHandler() {
            const {data} = this.$refs.signaturePad.saveSignature();
            let obj = {
                passport: this.passport,
                sign: data,
                signerName: this.signerName,
                signerPosition: this.signerPosition,
                companyRegistrationNumber: this.companyRegistrationNumber

            };
            this.$emit("submit", obj);
        },
    },
    mounted() {
        setTimeout(() => {
            this.open = true;
        }, 300);
    },
};
</script>

<style></style>
